import React from "react";

const FinalScreen = props => (
  <div className="final-screen">
    <ul className="final-nums">
      {props.numbers.map(x => (
        <li key={x}>{x}</li>
      ))}
    </ul>
    <h3 className="final-alert">Go out and place your bets!</h3>
  </div>
);

export default FinalScreen;
