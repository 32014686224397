import React, { Component } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import EmailAlert from "./EmailAlert.js";
import FinalScreen from "./FinalScreen.js";
import shuffle from "lodash.shuffle";
import Packet from "./static/packet.png";
import Packet2x from "./static/packet@2x.png";
import Packet3x from "./static/packet@3x.png";
import Intro from "./Intro.js";

class App extends Component {
  state = {
    numbers: [...Array(49).keys()],
    activeNumbers: [],
    allNumbersIn: false,
    showEmail: false,
    showFinalScreen: false,
    showIntro: true
  };

  componentWillMount() {
    let newnums = this.state.numbers;
    newnums[0] = 49;

    this.setState({
      numbers: shuffle(newnums)
    });
  }

  rl = () => {
    window.location.reload();
  };

  addNumber = n => {
    if (!this.state.activeNumbers.includes(n)) {
      if (this.state.activeNumbers.length < 5) {
        this.setState({ activeNumbers: [...this.state.activeNumbers, n] });
      } else if (this.state.activeNumbers.length === 5) {
        this.setState({ activeNumbers: [...this.state.activeNumbers, n] });
        this.setState({ allNumbersIn: true, showEmail: true });
      }
    }
  };

  activateFinalScreen = () => {
    this.setState({ showFinalScreen: true, showEmail: false });
  };

  dismissIntro = () => {
    this.setState({
      showIntro: false
    });
  };

  render() {
    return (
      <div className="App">
        {this.state.showEmail ? (
          <EmailAlert
            numbers={this.state.activeNumbers}
            done={() => this.activateFinalScreen()}
          />
        ) : null}
        {this.state.showIntro ? (
          <Intro done={() => this.dismissIntro()} />
        ) : null}
        <Header rl={() => this.rl()} />
        <h1 className="header-text">the good angbao</h1>
        <div className="body-container">
          <div className="packet">
            <img
              src={Packet}
              alt=" "
              srcSet={`${Packet}, ${Packet2x} 2x, ${Packet3x} 3x`}
            />
          </div>
          <div className="packet-title">
            <h2>猪你好运</h2>
            <h4>WISH YOU GOOD LUCK!</h4>
          </div>
          {!this.state.showFinalScreen && (
            <div className="numbers">
              {this.state.numbers.slice(19).map(x => (
                <li
                  key={x}
                  className={
                    this.state.activeNumbers.includes(x) ? "active" : "inactive"
                  }
                  onClick={() => this.addNumber(x)}
                >
                  {x}
                  <span className="after" />
                </li>
              ))}
            </div>
          )}
          {this.state.showFinalScreen && (
            <FinalScreen numbers={this.state.activeNumbers} />
          )}
          <div className="packet-footer">
            <p>scratch six auspicious digits for a toto windfall</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
