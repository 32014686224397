import React from "react";
import Hand from "./static/hand.svg";

const Intro = props => (
  <div className="intro-container" onClick={props.done}>
    <div className="intro-box">
      <div className="box-inner">
        <h3>six clicks does the trick</h3>
        <img src={Hand} alt="hand" />
      </div>
    </div>
    <h4>tap to dismiss</h4>
  </div>
);

export default Intro;
