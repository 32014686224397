import React from "react";
import Direct from "./static/direct.svg";
import FB from "./static/facebook.svg";
import WA from "./static/whatsapp.svg";

const Footer = props => (
  <div className="footer">
    <h3>SHARE THE LUCK</h3>
    <div className="icons">
      <a href="https://telegram.me/share/url?url=https://quickpig.goodstuph.org&text=Got lobang must share, click to huat!">
        <img src={Direct} alt="Share on Telegram!" />
      </a>
      <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A//quickpig.goodstuph.org">
        <img src={FB} alt="Share on Facebook! " />
      </a>
      <a
        href="whatsapp://send?text=Got lobang must share, click to huat! https%3A//quickpig.goodstuph.org"
        data-action="share/whatsapp/share"
      >
        <img src={WA} alt="Share on Whatsapp!" />
      </a>
    </div>
  </div>
);

export default Footer;
