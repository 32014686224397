import React from "react";
import Logo from "./static/logo.png";
import Reload from "./static/reload.svg";

const Header = props => (
  <div className="header">
    <img src={Reload} alt="reload" className="reload" onClick={props.rl} />
    <a
      rel="noopener noreferrer"
      href="https://facebook.com/goodstuph/"
      target="_blank"
    >
      <img src={Logo} alt="Goodstuph on Facebook" className="logo" />
    </a>
  </div>
);

export default Header;
