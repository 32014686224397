import React, { Component } from "react";
import axios from "axios";

class EmailAlert extends Component {
  state = {
    email: ""
  };

  handleEmailChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  sendEmail = () => {
    let rgx = /\S+@\S+\.\S+/;
    let valid = rgx.test(this.state.email);

    if (valid) {
      axios
        .post("/sendmail/", {
          email: this.state.email,
          numbers: this.props.numbers
        })
        .then(res => {
          if (res.data === "EMAIL_SENT") {
            this.props.done();
          }
        });
    } else {
      window.alert(
        "Sorry, that doesn't look like a valid email address. Please try again."
      );
    }
  };
  render() {
    return (
      <div className="email-container">
        <div className="email-box">
          <h1>Email me my lucky numbers!</h1>
          <input
            type="email"
            placeholder="enter email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
          <button className="email-button" onClick={this.sendEmail}>
            OK!
          </button>
        </div>
      </div>
    );
  }
}

export default EmailAlert;
